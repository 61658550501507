import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

import { CenteredDiv } from './Loading.styled'

export const Loading = () => (
  <CenteredDiv>
    <CircularProgress />
  </CenteredDiv>
)
