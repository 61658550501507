import * as ismApi from '@gmini/ism-api-sdk'
import * as smApi from '@gmini/sm-api-sdk'

import { Attribute } from '../Attribute'

import { AttributesStyled } from './Attributes.styled'

interface AttributesProps {
  filteredAuthors: smApi.Auth.UserData[]
  filteredAssignedUsers: smApi.Auth.UserData[]
  filteredCompanies: smApi.HubManagement.Company.Data[]
  filteredRoles: smApi.Auth.Role.Item[]
  filteredStatusTypes: ismApi.IssueStatus.Data[]
  filteredPeriod: string | null
}

export const Attributes: React.FC<AttributesProps> = ({
  filteredAuthors,
  filteredAssignedUsers,
  filteredCompanies,
  filteredRoles,
  filteredStatusTypes,
  filteredPeriod,
}) => (
  <AttributesStyled>
    {filteredStatusTypes.length > 0 && (
      <Attribute
        label='Статус:'
        value={filteredStatusTypes}
        fields={['name']}
      />
    )}
    {/* transfer data remap from render to service or hook */}

    {filteredAuthors.length > 0 && (
      <Attribute
        label='Авторы:'
        value={[
          ...filteredAuthors.map(user => ({
            firstName: user.firstName,
            lastName: user.lastName,
          })),
        ]}
        fields={['firstName', 'lastName']}
      />
    )}

    {filteredPeriod && (
      <Attribute
        label='Период создания:'
        value={[{ valuePeriod: filteredPeriod }]}
        fields={['valuePeriod']}
      />
    )}
    {/*put the condition into a variable */}
    {/* transfer data remap from render to service or hook */}
    {(filteredAssignedUsers.length > 0 ||
      filteredCompanies.length > 0 ||
      filteredRoles.length > 0) && (
      <Attribute
        label='Назначен на:'
        value={[
          ...filteredAssignedUsers.map(user => ({
            firstName: user.firstName,
            lastName: user.lastName,
          })),
          ...filteredCompanies.map(company => ({
            companyName: company.companyName,
          })),
          ...filteredRoles.map(role => ({ title: role.title })),
        ]}
        fields={['firstName', 'lastName', 'companyName', 'title']}
      />
    )}
  </AttributesStyled>
)
