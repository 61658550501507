import { useEffect } from 'react'

import Container from '@mui/material/Container'

import { useMainData } from '../hooks'

import {
  companiesService,
  issuesService,
  rolesService,
} from '../pages/MainPage/model'
import { Loading } from '../../common'

export const MainContainer = () => {
  const {
    companies,
    filters,
    isLoading,
    issueStatus,
    roles,
    users,
  } = useMainData()
  //TODO: remove console.log
  // eslint-disable-next-line no-console
  console.log('filters', filters)
  // eslint-disable-next-line no-console
  console.log('companies', companies)
  // eslint-disable-next-line no-console
  console.log('issueStatus', issueStatus)
  // eslint-disable-next-line no-console
  console.log('roles', roles)
  // eslint-disable-next-line no-console
  console.log('users', users)

  useEffect(() => {
    issuesService.fetchSavedFilters.submit()
    issuesService.fetchStatusList.submit()
    rolesService.fetchRoleList.submit()
    companiesService.fetchCompanyList.submit()
  }, [])

  if (isLoading) {
    return (
      <Container maxWidth='lg'>
        <Loading />
      </Container>
    )
  }

  return <Container maxWidth='lg'>Admin</Container>
}
