import { useStore } from 'effector-react'

import {
  companiesService,
  isLoading$,
  issuesService,
  rolesService,
  usersService,
} from '../pages/MainPage/model'

export const useMainData = () => {
  const filters = issuesService.useSavedFilterList()
  const users = usersService.useUserList()
  const roles = rolesService.useRoleList()
  const companies = companiesService.useCompanyList()
  const issueStatus = issuesService.useStatusList()

  const isLoading = useStore(isLoading$)

  return {
    companies,
    filters,
    issueStatus,
    isLoading,
    roles,
    users,
  }
}
