import styled from 'styled-components'

export const AttributeStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`

export const AttributeLabel = styled.div`
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.1px;
  color: var(--main-color);
  min-width: 120px;
`

export const AttributeValues = styled.div`
  display: flex;

  div {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.1px;
    color: var(--dark-blue-color);
  }
`

export const AttributeStatusValue = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  & + & {
    margin-left: 8px;
  }
`

export const AttributeSeparator = styled.div`
  width: 4px;
  height: 18px;
  border-radius: 4px;
`
