import { Omni } from '@gmini/sm-api-sdk'

export interface GroupedAttribute {
  attribute: string
  attributeLabel: string
  value: string[]
  valueLabels: string[]
  source?: string
}

export const groupAttributesByField = (
  attributes: Omni.Attribute[],
): GroupedAttribute[] => {
  const acc: { [key: string]: GroupedAttribute } = {}

  attributes.forEach(attr => {
    let key = attr.attribute

    if (attr.attribute === 'issue.assignees') {
      key = `${attr.attribute}.${attr.source?.toLocaleLowerCase() || 'payload'}`
    }

    if (!acc[key]) {
      acc[key] = {
        attribute: key,
        attributeLabel: attr.attributeLabel || '',
        value: [],
        valueLabels: [],
        source: attr.source,
      }
    }

    acc[key].value.push(attr.value)
    if (attr.valueLabel) {
      acc[key].valueLabels.push(attr.valueLabel)
    }
  })

  return Object.values(acc)
}
