import * as smApi from '@gmini/sm-api-sdk'
import * as ismApi from '@gmini/ism-api-sdk'

import { Header } from '../Header'

import { Modal } from '../Modal'
import { Attributes } from '../Attributes'

import { UnsubscribeWarningInfo } from '../UnsubscribeWarningInfo'

import { Box, UnsubscribeButton } from './Unsubscribe.styled'

type UnsubscribeProps = {
  isOpen: boolean
  handleClose: () => void
  filterName: string | undefined
  filteredAssignedUsers: smApi.Auth.UserData[]
  filteredAuthors: smApi.Auth.UserData[]
  filteredCompanies: smApi.HubManagement.Company.Data[]
  filteredPeriodValue: string | null
  filteredStatusTypes: ismApi.IssueStatus.Data[]
  filteredRoles: smApi.Auth.Role.Item[]
  handleClickOpen: () => void
  handleUnsubscribe: () => void
}

export const Unsubscribe = ({
  filterName,
  filteredAssignedUsers,
  filteredAuthors,
  filteredCompanies,
  filteredPeriodValue,
  filteredRoles,
  filteredStatusTypes,
  handleClose,
  isOpen,
  handleClickOpen,
  handleUnsubscribe,
}: UnsubscribeProps) => (
  <>
    <Box>
      <Header />
      <UnsubscribeWarningInfo filterName={filterName} />

      <Attributes
        filteredStatusTypes={filteredStatusTypes}
        filteredAuthors={filteredAuthors}
        filteredAssignedUsers={filteredAssignedUsers}
        filteredCompanies={filteredCompanies}
        filteredRoles={filteredRoles}
        filteredPeriod={filteredPeriodValue}
      />
      <UnsubscribeButton onClick={handleClickOpen}>
        Отписаться от уведомления
      </UnsubscribeButton>
    </Box>

    <Modal
      open={isOpen}
      handleClose={handleClose}
      handleUnsubscribe={handleUnsubscribe}
    />
  </>
)
