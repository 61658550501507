import { GroupedAttribute } from './groupAttributesByField'

export const filterEntitiesByAttribute = <T>(
  entities: T[],
  attributeKey: string,
  identifierKey: keyof T,
  groupedAttributes: GroupedAttribute[],
) => {
  const attributeValues = Array.isArray(groupedAttributes)
    ? groupedAttributes
        .filter(group => group.attribute === attributeKey)
        .flatMap(group => group.value)
    : []

  return entities.filter(entity =>
    attributeValues.includes(String(entity[identifierKey])),
  )
}
