import {
  AttributeStyled,
  AttributeLabel,
  AttributeValues,
} from './Attribute.styled'
import { CommonFields, formatEntities } from './formatEntities'

interface AttributeProps {
  label: string
  value: CommonFields[]
  fields?: string[]
}

export const Attribute: React.FC<AttributeProps> = ({
  label,
  value,
  fields,
}) => {
  // transfer data remap from render to service or hook
  const formattedValue = fields
    ? formatEntities(value, fields)
    : value.map(entity => JSON.stringify(entity)).join('')

  return (
    <AttributeStyled>
      <AttributeLabel>{label}</AttributeLabel>
      <AttributeValues>{formattedValue}</AttributeValues>
    </AttributeStyled>
  )
}
