import { useStore } from 'effector-react'

import { useMemo } from 'react'

import { filterEntitiesByAttribute } from '../helpers'
import {
  companiesService,
  isLoading$,
  issuesService,
  rolesService,
  unsubscribeService,
  usersService,
} from '../pages/UnsubscribePage/model'

export const useUnsubscribeData = () => {
  const userIds = unsubscribeService.useUniqueUserIds()
  const filters = issuesService.useSavedFilterList()
  const users = usersService.useUserList()
  const roles = rolesService.useRoleList()
  const assignedCompanies = companiesService.useCompanyList()
  const issueStatus = issuesService.useStatusList()
  const subscription = unsubscribeService.useSubscription()
  const filterName = filters.find(
    filter => filter.publicId === subscription?.publicId,
  )?.name
  const groupedAttributes = unsubscribeService.useGroupedAttributes()
  const isLoading = useStore(isLoading$)
  const unsubscribePending = useStore(unsubscribeService.unsubscribePending$)

  //TODO: transfer all reducing data to service
  const filteredPeriodValue = useMemo(
    () =>
      subscription?.attributes.find(
        attr => attr.attribute === 'issue.createdAt',
      )?.valueLabel || null,
    [subscription?.attributes],
  )

  const filteredAuthors = useMemo(
    () =>
      filterEntitiesByAttribute(
        users,
        'issue.ownerId',
        'id',
        groupedAttributes,
      ),
    [groupedAttributes, users],
  )

  const filteredStatusTypes = useMemo(
    () =>
      filterEntitiesByAttribute(
        issueStatus,
        'issue.status',
        'status',
        groupedAttributes,
      ),
    [groupedAttributes, issueStatus],
  )

  const filteredAssignedUsers = useMemo(
    () =>
      filterEntitiesByAttribute(
        users,
        'issue.assignees.user',
        'id',
        groupedAttributes,
      ),
    [groupedAttributes, users],
  )

  const filteredCompanies = useMemo(
    () =>
      filterEntitiesByAttribute(
        assignedCompanies,
        'issue.assignees.company',
        'id',
        groupedAttributes,
      ),
    [assignedCompanies, groupedAttributes],
  )

  const filteredRoles = useMemo(
    () =>
      filterEntitiesByAttribute(
        roles,
        'issue.assignees.role',
        'id',
        groupedAttributes,
      ),
    [groupedAttributes, roles],
  )

  return {
    userIds,
    subscription,
    filterName,
    isLoading,
    unsubscribePending,
    filteredPeriodValue,
    filteredAuthors,
    filteredStatusTypes,
    filteredAssignedUsers,
    filteredCompanies,
    filteredRoles,
  }
}
