import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
`

export const Box = styled.div`
  display: grid;
  align-items: start;
  justify-items: start;
`

export const UnsubscribeButton = styled.button`
  padding: 8px;
  appearance: none;
  border: none;
  color: var(--white-color);
  background: var(--blue-color);
  margin-top: 32px;
  border-radius: 4px;
  transition: 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    background: var(--dark-blue-color);
  }
`
