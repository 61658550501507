import React, { FC } from 'react'

import { PageTitle } from '@gmini/ui-kit'

type WithPageTitleProps = {
  title: string
}

export const WithPageTitle: FC<WithPageTitleProps> = ({ title, children }) => (
  <>
    <PageTitle>{title}</PageTitle>
    {children}
  </>
)
