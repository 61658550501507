import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

interface ModalProps {
  open: boolean
  handleClose: () => void
  handleUnsubscribe: () => void
}

export const Modal: React.FC<ModalProps> = ({
  open,
  handleClose,
  handleUnsubscribe,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Отписаться</DialogTitle>
    <DialogContent>
      <DialogContentText>Вы точно хотите отписаться?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Отмена</Button>
      <Button onClick={handleUnsubscribe} color='primary'>
        Подтвердить
      </Button>
    </DialogActions>
  </Dialog>
)
