import { RouteComponentProps } from 'react-router-dom'

import { UnsubscribeContainer } from '../../containers'
import { WithPageTitle } from '../../../common'

type MatchParams = {
  id: string
}

export const UnsubscribePage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { id } = match.params

  return (
    <WithPageTitle title='Отписаться'>
      <UnsubscribeContainer subscriptionId={id} />
    </WithPageTitle>
  )
}
