import { createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import { useStore } from 'effector-react'

export function createCompaniesService() {
  const fetchCompanyList = smApi.HubManagement.Company.fetchList.createContext()
  const fetchCompanyListPending$ = fetchCompanyList.pending$

  const companyList$ = createStore<smApi.HubManagement.Company.Data[]>([]).on(
    fetchCompanyList.doneData,
    (_state, result) => result,
  )

  const companyById$ = createStore<
    Record<string, smApi.HubManagement.Company.Data>
  >({}).on(fetchCompanyList.doneData, (_state, result) =>
    result.reduce(
      (acc: Record<string, smApi.HubManagement.Company.Data>, item) => {
        acc[item.id] = item

        return acc
      },
      {},
    ),
  )

  function useCompanyList() {
    return useStore(companyList$)
  }

  function useCompanyById() {
    return useStore(companyById$)
  }

  return {
    fetchCompanyList,
    fetchCompanyListPending$,
    companyList$,
    useCompanyList,
    companyById$,
    useCompanyById,
  }
}
