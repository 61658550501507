import styled from 'styled-components'

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 11px;
  letter-spacing: 0.01em;
`

export const HeaderLink = styled.a`
  font-size: 11px;
  letter-spacing: 0.01em;
  text-decoration: none;
`
