import { createPendingCombine } from '../../../../utils'
import {
  createCompaniesService,
  createIssuesService,
  createRolesService,
  createUsersService,
} from '../../../common'

export const usersService = createUsersService()
export const rolesService = createRolesService()
export const companiesService = createCompaniesService()
export const issuesService = createIssuesService()

export const isLoading$ = createPendingCombine([
  usersService.fetchUserListPending$,
  rolesService.fetchRoleListPending$,
  companiesService.fetchCompanyListPending$,
  issuesService.fetchSavedFiltersPending$,
  issuesService.fetchStatusListPending$,
])
