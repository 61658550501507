import { statusColors } from '../../../common'

import { AttributeSeparator, AttributeStatusValue } from './Attribute.styled'

export interface CommonFields {
  [key: string]: string | number | boolean | null | undefined
}

export const formatEntities = (
  entities: CommonFields[],
  fields?: string[],
): JSX.Element => {
  if (!entities || !fields) {
    return <></>
  }

  // TODO: rewrite this like this
  // 1. prepare data for rendering in service(added render type)
  // 2. render prepared data in switch case by type

  const formattedEntities: JSX.Element[] = []

  entities.forEach((entity, entityIndex) => {
    const keyPrefix = entity.status ? `status-${entity.status}` : 'entity'
    const entityKey = `${keyPrefix}-${entityIndex}`

    if (
      entity.status &&
      typeof entity.status === 'string' &&
      entity.status in statusColors
    ) {
      formattedEntities.push(
        <AttributeStatusValue key={entityKey}>
          <AttributeSeparator
            style={{
              backgroundColor:
                statusColors[entity.status as keyof typeof statusColors],
            }}
          />
          {fields.map(field => entity[field]).join(' ')}
        </AttributeStatusValue>,
      )
    } else {
      const combinedValues = fields
        .filter(field => Object.prototype.hasOwnProperty.call(entity, field))
        .map(field => entity[field])
        .join(' ')

      formattedEntities.push(
        <div key={entityKey}>
          {entityIndex > 0 ? ', ' : ''}
          {combinedValues}
        </div>,
      )
    }
  })

  return <>{formattedEntities}</>
}
