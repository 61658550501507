/* eslint-disable react/no-children-prop */
import 'regenerator-runtime/runtime.js'

import React from 'react'
import ReactDOM from 'react-dom'

import { connectSentry } from '@gmini/common'

import { App } from './modules/App'
import { sentryUrl, envType } from './config/config'

const { ErrorBoundary } = connectSentry({
  dsn: sentryUrl,
  release: process.env.NODE_PROJECT_VERSION || '',
  environment: envType,
})

ReactDOM.render(
  <ErrorBoundary children={<App />} />,
  document.getElementById('root'),
)
