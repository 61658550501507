import { createStore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { useStore } from 'effector-react'

import { GroupedAttribute, groupAttributesByField } from '../helpers'

export const createUnsubscribeService = () => {
  const fetchSubscriptionById = smApi.Omni.subscriptionById.createContext()
  const fetchSubscriptionByIdPending$ = fetchSubscriptionById.pending$

  const unsubscribe = smApi.Omni.unsubscribe.createContext()
  const unsubscribePending$ = unsubscribe.pending$

  const subscription$ = createStore<smApi.Omni.FilterType | null>(null).on(
    fetchSubscriptionById.doneData,
    (_, result) => result,
  )

  const groupedAttributes$ = createStore<GroupedAttribute[]>([]).on(
    subscription$,
    (_, subscription) => {
      if (subscription) {
        return groupAttributesByField(subscription.attributes)
      }
      return []
    },
  )

  const uniqueUserIds$ = createStore<string[]>([]).on(
    subscription$,
    (_, subscription) => {
      if (subscription) {
        const { attributes } = subscription

        const authorIds = attributes
          .filter(attr => attr.attribute === 'issue.ownerId')
          .map(attr => attr.value)

        const userIds = attributes
          .filter(
            attr =>
              attr.attribute === 'issue.assignees' && attr.source === 'USER',
          )
          .map(attr => attr.value)

        const uniqueUserIds = new Set([...authorIds, ...userIds])

        return Array.from(uniqueUserIds)
      }

      return []
    },
  )

  function useSubscription() {
    return useStore(subscription$)
  }

  function useGroupedAttributes() {
    return useStore(groupedAttributes$)
  }

  function useUniqueUserIds() {
    return useStore(uniqueUserIds$)
  }

  return {
    groupedAttributes$,
    uniqueUserIds$,
    fetchSubscriptionById,
    fetchSubscriptionByIdPending$,
    useSubscription,
    useGroupedAttributes,
    useUniqueUserIds,
    unsubscribe,
    unsubscribePending$,
  }
}
