import styled from 'styled-components'

export const UnsubscribeLogo = styled.img`
  width: 180px;
  height: 180px;
  margin-top: 58px;
`

export const UnsubscribeText = styled.div`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  max-width: 663px;
  margin-top: 49px;

  b {
    font-weight: 600;
  }
`
