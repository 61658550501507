import React from 'react'

import { TitleStyled } from './Title.styled'

type TitleProps = {
  title: string
}

export const Title = ({ title }: TitleProps) => (
  <TitleStyled>{title}</TitleStyled>
)
