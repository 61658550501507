import * as React from 'react'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'

import { DefaultSnackbarProvider, muiTheme, OfflineModal } from '@gmini/ui-kit'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import { MetrikaProvider } from '@gmini/common/lib/metrika/provider'

import { AuthContent } from '@gmini/common/lib/keycloakAuth/AuthContent'
import { saveAuthData } from '@gmini/common/lib/keycloakAuth/auth'

import { ApiCallService } from '@gmini/api-call-service'

import * as ismApi from '@gmini/ism-api-sdk'

import * as config from '../../config'

import { initAmplitude } from '../../config/amplitude'
import '../../api/omniService'
import '../../assets/css/global.css'
import '../../assets/css/variable.css'
import '@mui/styles'

import { pages } from './pages'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

initAmplitude()

export const App = (): JSX.Element => {
  const onTokens = React.useCallback(
    ({ idToken, refreshToken, token }: AuthClientTokens) => {
      if (!token || !refreshToken || !idToken) {
        // Если приходят пустые токены, то значит юзер разлогинился или залогинился под другим юзернеймом на другой вкладке
        // и кейклок сам сделает редирект на страницу авторизации что бы обновить куки
        return
      }

      saveAuthData({
        accessToken: token,
        refreshToken,
        idToken,
      })
    },
    [],
  )

  return (
    <ReactKeycloakProvider
      authClient={config.keycloakClient}
      onTokens={onTokens}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <MetrikaProvider>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <DefaultSnackbarProvider>
                  <CssBaseline />
                  <OfflineModal />
                  <Helmet>
                    <link
                      rel='stylesheet'
                      href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
                    />
                  </Helmet>
                  <AuthContent appName=''>
                    <Switch>
                      {pages.map(page => (
                        <Route
                          path={page.path}
                          component={page.component}
                          key={page.path}
                        />
                      ))}
                      <Route component={Page404} />
                    </Switch>
                  </AuthContent>
                </DefaultSnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </MetrikaProvider>
    </ReactKeycloakProvider>
  )
}

App.displayName = 'App'

ApiCallService.fail.watch(({ name, params, error }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (error.status === 'fail' || (error as any).name === 'AbortError') {
    return
  }

  ismApi.logApiError(error)
})
