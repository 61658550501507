import React from 'react'
import { GTechLogo } from '@gmini/ui-kit/lib/icons'

import { HeaderLink, HeaderStyled, HeaderText } from './Header.styled'

export const Header = () => (
  <HeaderStyled>
    <GTechLogo />
    <HeaderText>
      Global Technology Evolving for Construction{' '}
      <HeaderLink href='https://g-tec.ru' target='_blank'>
        https://g-tec.ru
      </HeaderLink>
    </HeaderText>
  </HeaderStyled>
)
