import { useEffect, useState } from 'react'

import Container from '@mui/material/Container'

import { useUnsubscribeData } from '../hooks'
import { Title, Unsubscribe } from '../components'
import {
  companiesService,
  issuesService,
  rolesService,
  unsubscribeService,
  usersService,
} from '../pages/UnsubscribePage/model'
import { Loading } from '../../common'

type UnsubscribeProps = {
  subscriptionId: string
}

export const UnsubscribeContainer = ({ subscriptionId }: UnsubscribeProps) => {
  const {
    filterName,
    filteredAssignedUsers,
    filteredAuthors,
    filteredCompanies,
    filteredPeriodValue,
    filteredRoles,
    filteredStatusTypes,
    isLoading,
    subscription,
    unsubscribePending,
    userIds,
  } = useUnsubscribeData()
  const [isUnsubscribed, setIsUnsubscribed] = useState(false)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    unsubscribeService.fetchSubscriptionById({ id: subscriptionId })
    issuesService.fetchSavedFilters.submit()
    issuesService.fetchStatusList.submit()
    rolesService.fetchRoleList.submit()
    companiesService.fetchCompanyList.submit()
  }, [subscriptionId])

  useEffect(() => {
    if (userIds.length) {
      usersService.fetchUserList({ ids: userIds })
    }
  }, [userIds])

  const handleUnsubscribe = async () => {
    try {
      setOpen(false)
      await unsubscribeService.unsubscribe({ id: subscriptionId })
    } catch (error) {
      console.error('Произошла ошибка при отписке:', error)
    } finally {
      setIsUnsubscribed(true)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (isLoading || unsubscribePending) {
    return (
      <Container maxWidth='lg'>
        <Loading />
      </Container>
    )
  }

  if (isUnsubscribed) {
    return (
      <Container maxWidth='lg'>
        <Title title='Вы успешно отписались' />
      </Container>
    )
  }

  return (
    <Container maxWidth='lg'>
      {subscription ? (
        <Unsubscribe
          filterName={filterName}
          filteredAssignedUsers={filteredAssignedUsers}
          filteredAuthors={filteredAuthors}
          filteredCompanies={filteredCompanies}
          filteredPeriodValue={filteredPeriodValue}
          filteredRoles={filteredRoles}
          filteredStatusTypes={filteredStatusTypes}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          handleUnsubscribe={handleUnsubscribe}
          isOpen={isOpen}
        />
      ) : (
        <Title title='Это не ваша подписка' />
      )}
    </Container>
  )
}
